@media (max-width:500px) {
    .user-profile-background {
        object-fit: cover;
        height: 94vh;
    }

    .user-profile-background img {
        width: 100%;
        height: 740;
    }

    .text-on-image {
        z-index: 9;
        margin-top: -45vh;
        color: #fff;
    } 

    .button-userP {
        width: 80%;
        background-color: #fff;
        color: black;
        border: none;
        border-radius: 5px;
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 18px;
        font-weight: bold;
    }

    .blur {
        filter: blur(1px);
    }

    .button-upd {
        padding: 8px;
        border-radius: 5px;
        background-color: #3c7493;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        border: none;
        outline: none;
        width: 60%;
    }

    .PrivateTabIndicator-colorSecondary-5 {
        background-color: #ea7f00 !important;
    }

    .card-view-publication {
        width: 100%;
        padding: 10px;
        box-shadow: none !important;
        background: transparent !important;
    }

    .Upublilcation-image-mob {
        width: 65px;
        height: 65px !important;
        border-radius: 4px;
        background-color: #ea7f00;
        object-fit: cover;
    }

    .Upublilcation-no-image-mob {
        max-width: 65px !important;
        height: 65px !important;
        border-radius: 4px;
        background-color: #ea7f00;
    }

    .thinkly-type-icon1-mob {
        z-index: 1;
        /* margin-top: -90px; */
        width: 40px;
        margin-left: -50px;
    }

    .thinkly-type-icon2-mob {
        z-index: 1;
        margin-top: 14px;
        width: 40px;
        margin-left: -1px;
    }

    .device-big-height {
        margin-top: 7rem;
    }

    /* read more and less */
    .addReadMore.showlesscontent .SecSec,
    .addReadMore.showlesscontent .readLess {
        display: none;
    }

    .addReadMore.showmorecontent .readMore {
        display: none;
    }

    .addReadMore .readMore,
    .addReadMore .readLess {
        font-weight: bold;
        margin-left: 2px;
        color: black;
        cursor: pointer;
    }

    .addReadMoreWrapTxt.showmorecontent .SecSec,
    .addReadMoreWrapTxt.showmorecontent .readLess {
        display: block;
    }
}