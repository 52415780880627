/* backgroud color */
.primary-bg-color {
    background:  #faa422;
}

.bg-custom {
    background: #fefce5;
}

.bg-white {
    background: #ffffff;
}

.bg-blue {
    background:#2baadf;
}

.bg-lightgray {
    background: #e0e0e0;
}

.bg-verylightgray {
    background: #f5f5f5;
}
/* border */
.border-none {
    border: none;
}

.border-radius-4 {
    border-radius: 4px;
}

.border-radius-50 {
    border-radius: 50%;
}

.border-radius-40 {
    border-radius: 40px;
}

.border-radius-6 {
    border-radius: 6px;
}

.border-color-primary {
    border-color: #faa422;
}

.border-color-btn {
    border-color: #208ebd;
}