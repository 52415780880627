.vertical-line {
    width: 100%;
    height: 1px;
    background-color: #c6b9b9;
}

.p-heading {
    font-size: 26px;
    font-weight: bold;
    font-family: Lora;
    align-items: center;
    display: flex;
    justify-content: center;
}


/* costomized multiple carousel arrow */
.react-multiple-carousel__arrow {
    background: gray !important;
    visibility: hidden;
    margin-top: -70px;
    /* outline: none !important; */
}

.react-multiple-carousel__arrow ::before {
    font-size: 32px;
}

.react-multi-carousel-list:hover .react-multiple-carousel__arrow {
    visibility: visible;
    /* outline: none !important; */
}

.react-multiple-carousel__arrow--left {
    left: 0;
}

.react-multiple-carousel__arrow--right {
    right: 0;
}

/* end carousel */


@media only screen and (min-width: 768px) {
    .story-content span {
        font-family: Lora;
        font-size: 36px;
        font-weight: bold;
    }

    .story-content p {
        font-weight: normal;
        font-size: 18px;
    }

    /* .story-content button {
        background: #2baadf;
        color: #fefcff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 5px;
    } */

    .subscribe {
        cursor: pointer;
        font-weight: 500;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        border: none;
        height: 40px;
        font-size: 18px;
        background: #F08F35;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .subscribed {
        background: #ffffff;
        color: #0a0a0a;
        border-color: #F08F35;
        font-size: 18px;
        font-weight: bold;
        border-radius: 4px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .t-in-p {
        width: 100%;
        border: none;
        box-shadow: none !important;
        background: transparent !important;
        padding: 10px;
    }

    .publilcation-image {
        width: 65px;
        height: 65px;
        border-radius: 4px;
    }

    .ma-profile {
        width: 120px !important;
        height: 120px !important;
        object-fit: cover;
        object-position: center;
    }

    .morePub-card {
        width: 250px !important;
        box-shadow: none !important;
        background: rgba(247, 247, 247, 0.49) !important;
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
    }

    .morePub-cardmedia {
        border-top-left-radius: 25px !important;
        border-top-right-radius: 25px !important;
        object-fit: cover;
        object-position: center;
        height: 250;
    }

    .pay-pub-profile {
        width: 150px !important;
        height: 150px !important;
        object-fit: cover;
        object-position: center;
    }

    .section-image-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }
}

@media only screen and (max-width: 766px) {
    .story-content span {
        font-family: Lora;
        font-size: 32px;
        font-weight: bold;
    }

    .story-content p {
        font-weight: normal;
        font-size: 18px;
    }

    /* .story-content button {
        background: #2baadf;
        color: #fefcff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 5px;
    } */

    .subscribe {
        cursor: pointer;
        font-weight: 500;
        border-radius: 4px;
        color: #fff;
        text-align: center;
        border: none;
        height: 40px;
        font-size: 18px;
        background: #F08F35;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .subscribed {
        background: #ffffff;
        color: #0a0a0a;
        border-color: #F08F35;
        font-size: 18px;
        font-weight: bold;
        border-radius: 4px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .t-in-p {
        width: 100%;
        border: none;
        box-shadow: none !important;
        background: transparent !important;
        padding: 10px;
    }

    .card-body {
        background: transparent !important;
        /* min-height: 240px; */
        min-width: 330px;
        margin: 1rem;
        padding: 0px !important;
    }

    /* for remove border or box style in meet the author  */
    .MuiPaper-elevation1 {
        box-shadow: none !important;
    }

    .publilcation-image {
        border-radius: 4px;
        height: 100px;
        width: 100px;
        object-fit: cover;
        object-position: center;
        padding: 0px;
    }

    .ma-profile {
        width: 90px !important;
        height: 90px !important;
        object-fit: cover;
        object-position: center;
    }

    .morePub-card {
        width: 90% !important;
        box-shadow: none !important;
        background: rgba(247, 247, 247, 0.49) !important;
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
    }

    .morePub-cardmedia {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        object-fit: cover;
        object-position: center;
    }

    .pay-pub-profile {
        width: 150px !important;
        height: 150px !important;
        object-fit: cover;
        object-position: center;
    }

    .section-image-center {
        display: block;
        width: 100%;
        /* height: 70%; */
    }
}