/* font-size */
.fs-5 {
    font-size: 5px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-26 {
    font-size: 26px;
}

.fs-28 {
    font-size: 28px;
}

.fs-30 {
    font-size: 30px;
}

.fs-36 {
    font-size: 36px;
}

.fs-56 {
    font-size: 56px;
}

.fs-40 {
    font-size: 40px;
}

.fs-64 {
    font-size: 64px;
}

/* font-weight */
.fw-normal {
    font-weight: normal;
}

.fw-mid {
    font-weight: 500;
}

.fw-mid-bold {
    font-weight: 700;
}

.fw-bold {
    font-weight: bold;
}

/* font-family */
.ff-lora {
    font-family: Lora;
}

.ff-roboto {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* font-color */
.fc-gray {
    color: #8f8f8f;
}

.fc-link {
    color: #3c7493;
}

.fc-white {
    color: #fff;
}

.fc-white-sub {
    color:#fefcff;
}

.fc-primary {
    color: #faa422;
}

.fc-black {
    color: #0a0a0a;
}

.fc-red {
    color: red;
}

.fc-green {
    color: green;
}

/*  */
.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-40 {
    width: 40%;
}

.w-30 {
    width: 30%;
}

.w-60 {
    width: 60%;
}

.w-80 {
    width: 80%;
}

.w-96 {
    width: 96%;
}

/* height */

.height-button {
    height: 40px;
}

/* pointer cursor */
.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.text-align-justify {
    text-align: justify;
}