
.left-content {
    border: none !important;
    background-color: transparent !important;
    /* height: 500 !important; */
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-left: 10px;
    padding-right: 10px;
}
.right-content {
    /* position: static; */
}

.button-follow {
    color: #fff;
    background-color: #3c7493;
    width: 180px;
    /* height: 34px; */
    border: none;
    border-radius: 5px;
    font-weight: 500;
    padding: 8px 20px;
}

.react-multi-carousel-list li {
    margin-right: 0px;
    display: grid;
    justify-items: center;
}

.button-thinklies {
    font-size: 18px;
    font-weight: bold;
    color: #8f8f8f;
    border-radius: 44px;
    border: solid 2px #ffe1b4;
    background-color: #fff;
    width: 102px;
    height: 40px
}

.button-thinklies :active {
    border: solid 2px #ffe1b4;
    background-color: #ffe1b4;
    color: #000;
}


@media only screen and (min-width: 768px) {
    .right-content img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: center;
    }
    .card-view-publication {
        width: 100%;
        /* padding: 8px; */
        border-radius: 4px !important;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1) !important;
    }
    
    .Upublilcation-no-image {
        max-width: 170% !important;
        height: 65px !important;
        border-radius: 4px;
        background-color: #ea7f00;
    }
    .Upublilcation-image {
        max-width: 170% !important;
        height: 65px !important;
        border-radius: 4px !important;
        margin-left: -15px;
    }
    .thinkly-type-icon1 {
        z-index: 1;
        margin-top: -92px;
        width: 40px;
        margin-left: 3px;
    }
    .thinkly-type-icon2 {
        z-index: 1;
        margin-top: 14px;
        width: 40px;
        margin-left: 3px;
    }
}

@media only screen and (max-width: 760px) {
    .right-content img {
        width: 280px;
        height: auto;
    }
}


/* tab part */
.card :active {
    background: none;
}
.PrivateTabIndicator-colorSecondary-5 {
    background-color: #ea7f00 !important;
}
.MuiTab-root {
    min-width: 140px !important;
}
/* .img-top-on-card {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-top: -25px;
    margin-left: 140px;
    z-index: 1;
    position: absolute;
} */

/* payment part */
.star-color {
    color: orange !important;
    /* background-image: linear-gradient(to top, yellow, orange); */
}

.numberCircle {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 10px 15px;
    background: #fff0d9;
    /* border: 2px solid #666; */
    color: black;
    text-align: center;
    font: 18px Arial, sans-serif;
} 

.numberCircleInput {
    /* border-radius: 50%; */
    width: 70px;
    height: 40px;
    /* padding: 8px 15px; */
    background: #fff0d9;
    border: none;
    color: black;
    text-align: center;
    font: 18px Arial, sans-serif;
    margin-top: -2px;
}

.numberCircleBorder {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 8px 15px;
    background: #fff0d9;
    color: black;
    text-align: center;
    font: 20px Arial, sans-serif;
    border: 2px solid #e98c37;
}

.numberCircleInputBorder {
    /* border-radius: 50%; */
    width: 70px;
    height: 40px;
    background: #fff0d9;
    border: none;
    color: black;
    text-align: center;
    font: 18px Arial, sans-serif;
    margin-top: -2px;
    outline: none;
    border: 2px solid #e98c37;
}

.star-support-button {
    outline: none;
    border: none;
    border-radius: 4px;
    background: #e98c37;
    width: 100%;
    height: 40px;
    color: #fff;
}

.text{
    display: inline;
    width: 100%;
  }
    
  .read-or-hide{
    color: #3c7493;
    cursor: pointer;
  }