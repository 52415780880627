.footer h3 {
    text-align: left;
    font: normal normal bold 17px/25px 'Roboto', Arial, Helevtica, sans-serif;
    letter-spacing: 1px;
    color: #FAA422;
    text-transform: uppercase;
}


@media only screen and (max-width: 600px) {
    .footer {
        /* background: #30333E 0% 0% no-repeat padding-box; */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        width: 100%;
        height: auto;
        /* padding-top: 10px;
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
        z-index: 1090;
        text-align: center; */
    }

    .footer .align-a a {
        font: normal;
        letter-spacing: 0px;
        /* color: #FFFFFF; */
        cursor: pointer;
        text-decoration: none;
        font-size: 12px;
    }

    .footer h4 {
        text-align: center;
        font: normal normal bold 12px/25px 'Roboto', Arial, Helevtica, sans-serif;
        letter-spacing: 0px;
        /* color: #FFFFFF; */
    }

    .downloadImgSize {
        width: 40%;
        height: 49px;
    }
}

@media only screen and (min-width: 601px) {
    .footer {
        margin-top: 100px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        width: 100%;
        height: auto;
        /* padding-top: 50px;
        padding-bottom: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1090;
        position: initial; */
    }

    .footer h4 {
        text-align: left;
        font: normal normal bold 17px/25px 'Roboto', Arial, Helevtica, sans-serif;
        letter-spacing: 0px;
        /* color: #FFFFFF; */
    }

    .footer .align-a a {
        font: normal;
        letter-spacing: 0px;
        /* color: #FFFFFF; */
        cursor: pointer;
        text-decoration: none;
    }

    .downloadImgSize {
        width: 165px;
        height: 49px;
    }
}

.footer p {
    text-align: left;
    font: normal normal normal 17px/28px 'Roboto', Arial, Helevtica, sans-serif;
    letter-spacing: 0px;
    /* color: #FFFFFF; */
}